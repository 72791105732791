import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import LayersIcon from "@material-ui/icons/Layers";
import { AccountTree, Apps, EmojiFoodBeverage } from "@material-ui/icons";

const buttonLink =
  "https://wa.me/6289519631440/?text=Permisi, saya tertarik untuk bekerja sama dengan Siesta setelah mengunjungi website siesta.id.";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 70,
  },
  brand: {
    lineHeight: 1,
    marginRight: "auto",
  },
  link: {
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  primaryAction: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  iconWrapper: {
    minWidth: 40,
  },
  icon: {
    color: theme.palette.text.hint,
  },
  drawerContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    width: 300,
  },
}));

export default function Navigation(props) {
  const classes = useStyles();

  const content = {
    brand: { image: "nereus-assets/img/nereus-light.png", width: 110 },
    link1: "Section One",
    link2: "Section Two",
    link3: "Section Three",
    link4: "Section Four",
    "primary-action": "Action",
    ...props.content,
  };

  let brand;

  if (content.brand.image) {
    brand = (
      <img src={content.brand.image} alt="" width={content.brand.width} />
    );
  } else {
    brand = content.brand.text || "";
  }

  const [state, setState] = React.useState({ open: false });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  return (
    <AppBar position="static" color="inherit">
      <Toolbar className={classes.toolbar}>
        <Link
          href="#"
          color="primary"
          underline="none"
          variant="h5"
          className={classes.brand}
        >
          {brand}
        </Link>
        <Link
          href="#our-works"
          color="textPrimary"
          variant="body2"
          className={classes.link}
        >
          {content["link1"]}
        </Link>
        <Link
          href="#our-products"
          color="textPrimary"
          variant="body2"
          className={classes.link}
        >
          {content["link2"]}
        </Link>
        <Link
          href="#our-services"
          color="textPrimary"
          variant="body2"
          className={classes.link}
        >
          {content["link3"]}
        </Link>
        {/* <Link
          href="#"
          color="textPrimary"
          variant="body2"
          className={classes.link}
        >
          {content["link4"]}
        </Link> */}
        {/* <Button
          variant="contained"
          color="secondary"
          className={classes.primaryAction}
          onClick={() => {
            window.open(buttonLink);
          }}
        >
          {content["primary-action"]}
        </Button> */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="left" open={state.open} onClose={toggleDrawer(false)}>
        <div className={classes.drawerContainer}>
          <Box
            mb={1}
            ml={2}
            pb={2}
            border={1}
            borderTop={0}
            borderLeft={0}
            borderRight={0}
            borderColor="background.emphasis"
          >
            <Link
              href="#"
              color="primary"
              underline="none"
              variant="h5"
              className={classes.linkBrand}
            >
              {brand}
            </Link>
          </Box>
          <List>
            <ListItem button key={content["link1"]}>
              <ListItemIcon className={classes.iconWrapper}>
                <LayersIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={content["link1"]} />
            </ListItem>
            <ListItem button key={content["link2"]}>
              <ListItemIcon className={classes.iconWrapper}>
                <Apps className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={content["link2"]} />
            </ListItem>
            <ListItem button key={content["link3"]}>
              <ListItemIcon className={classes.iconWrapper}>
                <EmojiFoodBeverage className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={content["link3"]} />
            </ListItem>
            <ListItem button key={content["link4"]}>
              <ListItemIcon className={classes.iconWrapper}>
                <AccountTree className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={content["link4"]} />
            </ListItem>
          </List>
          <Box
            mt={1}
            ml={2}
            pt={3}
            border={1}
            borderBottom={0}
            borderLeft={0}
            borderRight={0}
            borderColor="background.emphasis"
          >
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => {
                window.open(buttonLink);
              }}
            >
              {content["primary-action"]}
            </Button>
          </Box>
        </div>
      </Drawer>
    </AppBar>
  );
}
