import React from "react";

import HorizontalNav2 from "../components/horizontal-navs/HorizontalNav2";
import HowItWorks2 from "../components/how-it-works/HowItWorks2";
import Testimonials1 from "../components/testimonials/Testimonials1";
import Content3 from "../components/content/Content3";
import Content2 from "../components/content/Content2";
import Features5 from "../components/features/Features5";
import Contact1 from "../components/contacts/Contact1";
import Portfolio from "../components/portfolio/Portfolio3";
import { Box, Container, Typography } from "@material-ui/core";

export default function Index() {
  return (
    <React.Fragment>
      <HorizontalNav2
        content={{
          brand: {
            text: "Siesta",
            image: "siesta.png",
            width: "110",
          },
          link1: "Our Works",
          link2: "Our Products",
          link3: "Our Services",
          link4: "Our Clients",
          "primary-action": "Work With Us",
        }}
      />

      <HowItWorks2
        content={{
          badge: "Yes, you've found the right one",
          "header-p1": "Siesta is exactly",
          "header-p2": "your preferred and reliable tech consulting company",
          description:
            "We will help you materialize your vision and make your digital transformation as easy as a breeze.",
          "col1-header": "10+ years of experience",
          "col2-header": "Cloud-based integration",
          "col3-header": "Rapid development",
        }}
      />

      <Testimonials1
        content={{
          header:
            "We are the people behind many successful banks, fintechs & e-commerce startup platforms, including government",
          logo1: "images/erasmus.svg",
          logo2: "images/amartha.jpeg",
          logo3: "images/pos.png",
          logo4: "images/kedubes.jpeg",
          logo5: "images/bni.png",
          logo6: "images/mandiri.png",
          logo7: "images/bri.png",
          logo8: "images/centra.jpeg",
          logo9: "images/prasmul.png",
          logo10: "images/icon.jpeg",
          logo11: "images/erajaya.png",
          logo12: "images/20fit.jpeg",
        }}
      />

      <Portfolio
        content={{
          badge: "Our Awards",
          "header-p1": "1st",
          "header-p2": "Winner",
          description: "of Google x BRI Indonesia Hackaton 2020",
          image1: "IMG_2141.JPG",
          image2: "IMG_2156.JPG",
          image3: "",
          image4: "",
        }}
      />

      <Box pt={12} textAlign="center">
        <Container maxWidth="sm">
          <Typography variant="overline" color="textSecondary" paragraph={true}>
            {"Our Products"}
          </Typography>
        </Container>
      </Box>

      <Content3
        content={{
          "header-p1": "Al-YasiniQu, ",
          "header-p2": "a Digital Pesantren for the era of 4.0",
          description:
            "Al YasiniQu is our product dedicated for Pesantren & under pilot in Pesantren Al Yasini that is aimmed to be the super apps of services within Pesantren ecosystem - be it cashless payment, edu bill payment, learning management system, and pesantrens' ERP for their business units.",
          "primary-action": "Learn More",
          link: "https://play.google.com/store/apps/details?id=id.siesta.app.madrasahqu.alyasini",
          image1: "images/products/p11.jpeg",
          image2: "images/products/p12.jpeg",
          image3: "images/products/p13.jpeg",
          image4: "images/products/p14.jpeg",
        }}
      />

      <Content2
        content={{
          "header-p1": "LapakQu, ",
          "header-p2": "empowering SME (UMKM) in Indonesia towards hyperscale",
          description:
            "LapakQu has been carefully crafted for SME (UMKM) in Indonesia to help them solving their problems such as digital & cashless payment, online store (e-store), as well powerful as monitoring & reporting tools embedded so that SME can automate lots of their business flows and hence they can operate in a very efficient manner.",
          "primary-action": "Learn More",
          link: "https://lapakqu.id/",
          image1: "images/products/p21.jpeg",
          image2: "images/products/p22.jpeg",
          image3: "images/products/p23.jpeg",
          image4: "images/products/p24.jpeg",
        }}
      />

      <Content3
        content={{
          "header-p1": "RetailQu, ",
          "header-p2":
            "your transformative retail toolkit beyond a retail business",
          description:
            "RetailQu is a solution for retail businesses to scale up and transform them to be a new retail 4.0 through  integration with technology in supply chain, delivery, and finance.",
          "primary-action": "Learn More",
          link: "https://retailqu.id/",
          image1: "images/products/p31.jpeg",
          image2: "images/products/p32.jpeg",
          image3: "images/products/p33.jpeg",
          image4: "images/products/p34.jpeg",
        }}
      />

      <Features5
        content={{
          badge: "Our Services",
          "col1-desc": "IT Consulting",
          "col2-desc": "Authorized Gold Distributor",
          "col3-desc": "Payment Point Aggregator & PPOB",
          "col4-desc": "Banking & Cash Management",
          "col5-desc": "Courier & Logistics",
        }}
      />

      {/* <Testimonials2
        content={{
          header: "Our Partners and (very satisfied) clients",
        }}
      /> */}

      <Contact1
        content={{
          header: "SIESTA",
          description1: "is a brand of",
          description2: "PT. Solusi Infotech Semesta Indonesia",
          description3: "PT. Solusi Infotech Nuswantara",
          "contact2-desc": "business@siesta.id",
          "contact1-desc1": "Jl. Ciganitri Pertanian",
          "contact1-desc2":
            "Lengkong, Kec. Bojongsoang, Bandung, Jawa Barat 40287",
          // "contact4-desc": "+62 895 1963 1440",
        }}
      />
    </React.Fragment>
  );
}
